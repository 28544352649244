import React from 'react';
import Button from 'react-bootstrap/Button'
import Layout from '../components/layout';

const Akorin = () => {
  return ( 
    <Layout pageTitle="Akorin" pageLang="en_US" 
      pageDesc="A line-by-line audio recording tool for singing synthesis libraries">
      <p>The OREMO killer: A line-by-line audio recording tool for singing synthesis libraries.</p>
      <p>Features:</p>
      <ul>
        <li>Load several reclist formats, including a custom format that combines the reclist with notes</li>
        <li>Load Shift JIS and UTF-8 reclists</li>
        <li>Split plain text lists by all whitespace or by newline</li>
        <li>Set a destination folder for auto-saved recordings</li>
        <li>Record from any input or output, and play back through any output</li>
        <li>Set input/output levels</li>
        <li>Customize font size of selected line</li>
        <li>Display waveform and customize color of waveform</li>
        <li>Highlight lines that have already been recorded</li>
        <li>Save ARP (Akorin Recording Project) files</li>
        <li>Set your own default project settings for all new projects</li>
      </ul>
      <Button variant="primary" size="lg" className="text-white" href="https://github.com/adlez27/akorin">
        GitHub Repo
      </Button>
    </Layout>
  );
}
 
export default Akorin;